import { createContext } from 'react';
import { observable, makeObservable, action, runInAction } from 'mobx';
import { createUrlWithParams } from '../Helper';
import { clientApplicationsGql, currentUserClientsGql, currentUserFrontendPermissionsGql } from '../stores/gql';

class PlatformStore {
  constructor(apolloClient) {
    this.currentRoute = null;
    this.currentUser = null;
    this.currentClient = null;
    this.currentClientCode = null;
    this.currentUserFrontendPermissions = [];
    this.selectedAppPlatform = null;
    this.selectedApplication = null;
    this.currentModule = null;
    this.appPlatforms = [];
    this.applications = [];
    this.modules = [];
    this.clients = [];
    this.processingSwitchClient = false;
    this.isDemoModeEnabled = window.sessionStorage.getItem('isDemoModeEnabled') === 'true';
    this.demoClientCode = window.sessionStorage.getItem('demoClientCode');

    this.apolloClient = apolloClient;
    this.refetchCurrentModule();

    makeObservable(this, {
      currentRoute: observable,
      currentUser: observable,
      currentClient: observable,
      currentClientCode: observable,
      currentUserFrontendPermissions: observable,
      selectedAppPlatform: observable,
      selectedApplication: observable,
      currentModule: observable,
      appPlatforms: observable,
      applications: observable,
      modules: observable,
      clients: observable,
      processingSwitchClient: observable,
      demoClientCode: observable,
      isDemoModeEnabled: observable,
      setIsDemoModeEnabled: action,
      loadApplications: action,
      loadFrontendPermissions: action,
      loadClients: action,
      setParamsBasedOnUrl: action,
      setSelectedAppPlatform: action,
      setSelectedApplication: action,
      setCurrentClientCode: action,
      setCurrentModule: action,
      setStateFromUserStore: action,
      setProcessingSwitchClient: action,
      refetchCurrentModule: action,
    });
  }

  setStateFromUserStore (currentUser, currentClient, currentUserFrontendPermissions, clients) {
    this.currentClient = currentClient
    this.currentClientCode = currentClient.code
    this.currentUser = currentUser
    this.currentUserFrontendPermissions = currentUserFrontendPermissions || []
    this.modules = [];
    this.clients = clients || [];
    this._setModulesBasedOnPermissions();
  }

  setCurrentModule (currentModule) {
    const d = new Date();
    d.setTime(d.getTime() + (4 * 60 * 60 * 1000));
    document.cookie = `currentModule=${currentModule}; expires=${d.toUTCString()}; path=/;`
    this.currentModule = currentModule
  }

  refetchCurrentModule() {
    let cookies = document.cookie.split('; ').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = value;
      return acc
    }, {});
    this.currentModule = cookies['currentModule'];
  }

  setSelectedAppPlatform (selectedAppPlatform) {
    this.selectedAppPlatform = selectedAppPlatform
    if (!selectedAppPlatform) return localStorage.removeItem('appPlatformCode')
    localStorage.setItem('appPlatformCode', selectedAppPlatform.code)
  }

  setSelectedApplication (selectedApplication) {
    this.selectedApplication = selectedApplication;
    if (!selectedApplication) return localStorage.removeItem("applicationCode");
    localStorage.setItem("applicationCode", selectedApplication.code);
  }

  setProcessingSwitchClient (processingSwitchClient) {
    this.processingSwitchClient = processingSwitchClient
  }

  setCurrentClientCode (clientCode) {
    this.currentClientCode = clientCode
  }

  async loadClients() {
    const result = await this.apolloClient.query({
      query: currentUserClientsGql,
      fetchPolicy: "no-cache",
    });
    runInAction(() => {
      this.clients = result.data.currentUserClients;
    });    
  }

  async loadFrontendPermissions() {
    const result = await this.apolloClient.query({
      query: currentUserFrontendPermissionsGql,
      fetchPolicy: "no-cache",
    });
    runInAction(() => {
      this.currentUserFrontendPermissions = result.data.currentUserFrontendPermissions;
      this._setModulesBasedOnPermissions();
    });
  }

  async loadApplications() {
    if (!this.currentClient || !this.currentClient.code) return;
    const clientApplicationsResult = await this.apolloClient.query({
      query: clientApplicationsGql,
      fetchPolicy: "no-cache",
      variables: { clientCode: this.currentClient.code },
    });

    runInAction(() => {
      this.applications = clientApplicationsResult.data.clientApplications;
      this.appPlatforms = this.applications.reduce((acc, application) => {
        application.appPlatform &&
          acc.push(
            ...application.appPlatform.map((platform) => ({
              ...platform,
              name: application.name,
              asoGroupId: application.asoGroupId,
            })),
          );
        return acc;
      }, []);
  
      const lastAppPlatformCode = localStorage.getItem('appPlatformCode');
      const lastApplicationCode = localStorage.getItem('applicationCode');
      this.selectedAppPlatform = this.appPlatforms.find(({ code }) => code === lastAppPlatformCode);
      this.selectedApplication = this.applications.find(({ code }) => code === lastApplicationCode);
    });
  }

  setParamsBasedOnUrl (currentRoute, location = {}) {
    const searchParams = new URLSearchParams(location.search);

    this.currentRoute = currentRoute;
    if (currentRoute.path.includes('/ua/')) this.setCurrentModule('ua')
    if (currentRoute.path.includes('/creative/')) this.setCurrentModule('creative')
    if (currentRoute.path.includes('/social/')) this.setCurrentModule('social')
    if (currentRoute.path.includes('/upptic/')) this.setCurrentModule('upptic')
    if (currentRoute.path.includes('/client/')) this.setCurrentModule('client')
    if (currentRoute.path.includes('/settings/')) this.setCurrentModule('client')
    if (currentRoute.path.includes('/user/')) this.setCurrentModule('client')
    if (currentRoute.path.includes('/hub/')) this.setCurrentModule('hub')
    if (currentRoute.path.startsWith('/applications')) this.setCurrentModule('aso')

    if (
      !this.applications.length &&
      this.currentUser &&
      !currentRoute.path.startsWith('/:clientCode/applications') &&
      !currentRoute.path.startsWith('/:clientCode/upptic') &&
      !currentRoute.path.startsWith('/:clientCode/user') &&
      !currentRoute.path.startsWith('/logout') &&
      !currentRoute.path.startsWith('/sign-in')
    ) return `/${this.currentClient.code}/applications`

    // if ASO route is called set selectedAppPlatform passed on asoAppId
    if (currentRoute.params.id && currentRoute.path.includes('/applications/:id')) {
      const currentApp = this.appPlatforms.find(({ asoAppId }) => asoAppId === currentRoute.params.id)
      if (currentApp) {
        this.setSelectedAppPlatform(currentApp)
        const parentApplication = this.applications.find(({ asoGroupId }) => asoGroupId === currentApp.asoGroupId)
        this.setSelectedApplication(parentApplication)
      }
      return
    }

    // if appPlatformCode is passed find the app platform and set it (for new modules)
    if (currentRoute.params.appPlatformCode) {
      const selectedAppPlatforms = this.appPlatforms.find(({ code }) => code === currentRoute.params.appPlatformCode)
      // in case the current selected app platform is not available select the first one and rewrite the url
      this.setSelectedAppPlatform(selectedAppPlatforms || this.appPlatforms[0])
      if (!selectedAppPlatforms && this.appPlatforms.length) {
        return createUrlWithParams(currentRoute.path, {
          ...currentRoute.params,
          appPlatformCode: this.appPlatforms[0].code
        })
      }
      return
    }
    if (currentRoute.params.applicationCode) {
      const selectedApplication = this.applications.find(
        ({ code }) => code === currentRoute.params.applicationCode,
      );
      // in case the current selected application is not available select the first one and rewrite the url
      this.setSelectedApplication(selectedApplication || this.applications[0]);
      if (!selectedApplication && this.applications.length) {
        return createUrlWithParams(currentRoute.path, {
          ...currentRoute.params,
          applicationCode: this.applications[0].code,
        });
      }
      return;
    }

    if (searchParams.has('applicationCode')) {
      const selectedApplication = this.applications.find(
        ({ code }) => code === searchParams.get('applicationCode'),
      );
      if (selectedApplication) {
        this.setSelectedApplication(selectedApplication);
        return
      }
    }

    if (this.selectedAppPlatform && !this.selectedApplication) {
      this.setSelectedApplication(this.applications[0]);
    }
    // if no appPlatform is passed, keep the currently selected appPlatform
    if (this.selectedAppPlatform && this.appPlatforms.find(({ code }) => code === this.selectedAppPlatform.code)) return
    if (this.selectedApplication && this.applications.find(({ code }) => code === this.selectedApplication.code)) return
    // if no appPlatform is passed and no app platform is selected --> Take the first one
    if (this.applications.length) this.setSelectedApplication(this.applications[0]);
    if (this.appPlatforms.length) this.setSelectedAppPlatform(this.appPlatforms[0]);
  }

  setIsDemoModeEnabled(isDemoModeEnabled) {
    console.log('setIsDemoModeEnabled', isDemoModeEnabled)
    this.isDemoModeEnabled = isDemoModeEnabled;
    window.sessionStorage.setItem('isDemoModeEnabled', isDemoModeEnabled.toString())
    if (!isDemoModeEnabled) {
      this.demoClientCode = undefined;
      window.sessionStorage.removeItem('demoClientCode')
    }
    if (isDemoModeEnabled) {
      this.demoClientCode = this.currentClientCode;
      window.sessionStorage.setItem('demoClientCode', this.currentClientCode)
    }
  }

  switchApplication (newApplicationCode) {
    const selectedNewApp = this.applications.find(
      (singleAppGroup) => singleAppGroup.code === newApplicationCode,
    );
    if (!selectedNewApp) return false;

    const firstAppPlatform = selectedNewApp.appPlatform[0];
    this.setSelectedApplication(selectedNewApp);
    this.setSelectedAppPlatform(firstAppPlatform);
    return true;
  }

  _setModulesBasedOnPermissions() {
    if (this.currentUserFrontendPermissions.includes('PHHOR')) this.modules.push('hub')
    if (this.currentUserFrontendPermissions.includes('PAR')) this.modules.push('aso')
    if (this.currentUserFrontendPermissions.includes('PUR')) this.modules.push('ua')
    if (this.currentUserFrontendPermissions.includes('PCR')) this.modules.push('creative')
    if (this.currentUserFrontendPermissions.includes('PSR')) this.modules.push('social')
    this.modules.push('client')
  }
}

export const PlatformContext = createContext();

export default PlatformStore;
