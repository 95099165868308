import gql from "graphql-tag";

export const clientApplicationsGql = gql`
  query clientApplications($clientCode: String!) {
    clientApplications(clientCode: $clientCode, onlyAvailableAppsForUser: true) {
      code
      name
      asoGroupId
      status
      image
      sequenceId
      audit {
        createDate
      }
      appPlatform {
        code
        platform
        status
        appPublicId
        verifiedDataDate
      }
    }
  }
`;

export const currentUserClientsGql = gql`
  query currentUserClients {
    currentUserClients {
      code
      name
      status
    }
  }
`;

export const currentUserFrontendPermissionsGql = gql`
  query currentUserFrontendPermissions {
    currentUserFrontendPermissions
  }
`;
