const merge = require('deepmerge')

export const nonAuthRoutes = []
export const routes = []
export const defaultRoutes = []
export const menus = {}
export const locales = {}
export const entitySelectors = {}

class ModuleDirectory {
  addRoute (route) {
    routes.push(route)
  }

  addNonAuthRoute (route) {
    nonAuthRoutes.push(route)
  }

  addDefaultRoute (route) {
    defaultRoutes.push(route)
  }

  setMenu (name, entries, type) {
    menus[name] = { entries, type }
  }

  addLocale (localeCode, localeObj) {
    locales[localeCode] = merge(locales[localeCode] || {}, localeObj)
  }

  addEntitySelector(entity, Component) {
    entitySelectors[entity] = Component
  }
}

export { default as PlatformStore, PlatformContext } from './src/stores/platformStore'
export { EntitySelectorContext } from './src/stores/EntitySelectorContext'

export default ModuleDirectory
